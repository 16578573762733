import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { auth, googleAuthProvider } from "../../firebase";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import "./AuthModal.css";

const AuthModal = ({ isOpen, onRequestClose, fromApply, id, positionName }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const modalRef = useRef(null);
  const [showModal, setShowModal] = useState(isOpen);
  const navigate = useNavigate();
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShowModal(true);
    }
  }, [isOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;
    let modalInstance = null;

    if (modalElement && showModal) {
      modalInstance = new window.bootstrap.Modal(modalElement, {
        backdrop: "static",
      });
      modalInstance.show();

      modalElement.addEventListener("hidden.bs.modal", handleHidden);

      return () => {
        modalElement.removeEventListener("hidden.bs.modal", handleHidden);
        modalInstance.dispose();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleHidden = () => {
    setShowModal(false);
    onRequestClose();
  };

  const handleClose = () => {
    if (modalRef.current) {
      const modalInstance = window.bootstrap.Modal.getInstance(modalRef.current);
      if (modalInstance) {
        modalInstance.hide();
      }
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      showToast("Check your Inbox!", "Password reset email sent. Please check your inbox.");
      setIsForgotPassword(false);
    } catch (error) {
      if (error.message === "Firebase: Error (auth/missing-email).") {
        showToast("Field is empty", "Kindly write your email!");
      } else {
        showToast("Error!", "Error sending password reset email: " + error.message);
      }
    }
  };

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      if (user.emailVerified) {
        handleClose();
        if (fromApply) {
          setTimeout(() => {
            navigate("/wizard", {
              state: { id: id, positionName: positionName },
            });
          }, 500);
        }
      } else {
        showToast("Check your email!", "Please verify your email before logging in.");
      }
    } catch (error) {
      if (error.message === "Firebase: Error (auth/invalid-credential).") {
        showToast("Are you sure!", "Double check your Credentials");
      } else {
        showToast("Error!", "Error logging in: " + error.message);
      }
    }
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, googleAuthProvider)
      .then((result) => {
        const user = result.user;
        if (user.emailVerified) {
          handleClose();
          if (fromApply) {
            setTimeout(() => {
              navigate("/wizard", {
                state: { id: id, positionName: positionName },
              });
            }, 500);
          }
        } else {
          showToast("Check your email!", "Please verify your email before logging in.");
        }
      })
      .catch((error) => {
        if (
          error.message === "Firebase: Error (auth/cancelled-popup-request)." ||
          error.message === "Firebase: Error (auth/popup-closed-by-user)."
        ) {
          showToast("Wait!", "You backed up or Canceled!");
        } else {
          showToast("Error!", "Error signing up-1:" + error.message);
        }
      });
  };

  const handleSignUp = async () => {
    let step = 0;
    try {
      step++;
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      step++;
      await sendEmailVerification(userCredential.user);
      step++;
      showToast("Check your Inbox!", "Verification email sent. Please check your inbox.");
      step++;
      handleClose();
      step++;
      setTimeout(() => {
        navigate("/verification");
      }, 1500);
      step++;
    } catch (error) {
      if (error.message === "Firebase: Error (auth/email-already-in-use).") {
        showToast("Wait!", "This email is already signed up! Did you check your inbox?");
      } else {
        showToast("Error!", "Error signing up-2: " + step + "-" + error.message);
      }
    }
  };

  const showToast = (header, message) => {
    const toastElement = document.getElementById("liveToast");
    const toastHeader = toastElement.querySelector(".me-auto");
    const toastBody = toastElement.querySelector(".toast-body");
    toastHeader.textContent = header;
    toastBody.textContent = message;
    const toast = new window.bootstrap.Toast(toastElement, {
      delay: 5000, // one minute
    });
    toast.show();
  };

  return (
    <>
      {showModal && (
        <div className="modal fade" id="authModal" aria-labelledby="authModalLabel" aria-hidden="true" ref={modalRef}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="authModalLabel">
                  {isSignUp ? "Sign Up" : isForgotPassword ? "Reset Password" : "Login"}
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control mb-2"
                />
                {!isForgotPassword && (
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control mb-2"
                  />
                )}
                {isSignUp ? (
                  <button onClick={handleSignUp} className="btn btn-primary w-100 mb-2">
                    Sign Up
                  </button>
                ) : isForgotPassword ? (
                  <button onClick={handlePasswordReset} className="btn btn-primary w-100 mb-2">
                    Reset Password
                  </button>
                ) : (
                  <>
                    <button onClick={handleLogin} className="btn btn-success button w-100 mb-2">
                      Login
                    </button>
                    <button className="btn btn-primary w-100 mb-2" onClick={signInWithGoogle}>
                      <img
                        src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                        alt="Google Icon"
                        width={"20px"}
                      />
                      &nbsp;Login with Google
                    </button>
                    <button className="btn btn-link w-100 mb-2" onClick={() => setIsForgotPassword(true)}>
                      Forgot Password?
                    </button>
                  </>
                )}
              </div>
              <div className="modal-footer">
                <p className="w-100 text-center">
                  {isForgotPassword ? (
                    <button onClick={() => setIsForgotPassword(false)} className="btn btn-link">
                      Back to Login
                    </button>
                  ) : isSignUp ? (
                    <>
                      Already have an account?{" "}
                      <button onClick={() => setIsSignUp(false)} className="btn btn-link">
                        Login
                      </button>
                    </>
                  ) : (
                    <>
                      Don't have an account?{" "}
                      <button onClick={() => setIsSignUp(true)} className="btn btn-link">
                        Sign Up
                      </button>
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AuthModal;
